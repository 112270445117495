<template>
  <div class="main-div vasion-calc-height">
    <div class="inner-div">
      <div class="form-div">
        <FormioFormBuilder
          v-if="eForm"
          :key="'new'"
          ref="FormBuilder"
          :eForm="eForm"
          :isCopy="isCopy"
          @dirty="isDirty = true"
          @clean="isDirty = false"
        />
      </div>
    </div>
    <md-dialog id="confirmLeave" :md-active.sync="showLeaveDialog" @md-clicked-outside="clickedOutsideLeave()">
      <VasionConfirmationDialog :message="leaveMessage" @noButtonClick="cancelLeave()" @yesButtonClick="doLeave()" />
    </md-dialog>
  </div>
</template>

<script>
import FormioFormBuilder from '@/components/forms/FormioFormBuilder.vue';

export default {
  name: 'TheNewFormView',
  components: {
    FormioFormBuilder,
  },
  beforeRouteLeave(to, from, next) {
    if (this.isDirty && !this.routeTo) {
      this.routeTo = to
      this.toggleLeaveDialog()
    } else {
      this.$store.dispatch('forms/clearEform')
      if (this.$refs.FormBuilder) {
        this.$refs.FormBuilder.clearTemplateSelectionOptions()
      }
      next()
    }
  },
  props: {
    isCopy: {
      type: Boolean,
      required: false,
      default: false,
    },
    templateSave: {
      type: Boolean,
      required: false,
    },
  },
  data: function() {
    return {
      eForm: null,
      formsURL: null,
      isDirty: false,
      showLeaveDialog: false,
    }
  },
  computed: {
    leaveMessage() { return `Are you sure you want to leave? Changes to the form have not been saved.` },
  },
  created: async function () {
    await Promise.all([
      this.$store.dispatch('forms/getEForm', this.$route.params.formLayoutID),
      this.$store.dispatch('common/getIndexFieldsForForm', this.$route.params.formID)
    ])

    this.eForm = this.$store.state.forms.eForm
    this.eForm.IndexFormID = this.$route.params.formID

    this.formsURL = this.$store.state.common.formsURL
  },
  methods: {
    cancelLeave() {
      this.routeTo = null
      this.toggleLeaveDialog()
    },
    clickedOutsideLeave() { this.routeTo = null },
    async doLeave() {
      this.isDirty = false
      this.toggleLeaveDialog()
      this.$router.push({ name: this.routeTo.name });
      this.routeTo = null
    },
    toggleLeaveDialog() { this.showLeaveDialog = !this.showLeaveDialog },
  },
}
</script>

<style lang="scss" scoped>
  .inner-div {
    width: 100%;
    height: 72px;
  }

  .main-div {
    width: 100%;
    background-color: #f1f2f3;
  }
</style>
