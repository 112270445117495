<template>
  <div>
    <div v-show="saveEFormsPayload && !showPreview" id="app">
      <div class="formio-header">
        <div class="md-layout-item vasion-page-title left-padding margin-bottom">
          <VasionInput
            id="new-formio-title"
            v-model="saveEFormsPayload.Name"
            name="new-formio-title"
            class="input-style"
            inputType="title-input"
            :autoFocus="true"
            :isTitleInput="true"
            :placeholder="`Untitled ${$layoutsLabel}`"
            @input="markAsDirty()"
          />
        </div>
        <div v-if="selectedTabName === 'build'" class="with-fix">
          <div class="formio-input-extension">
            <VasionButton
              id="formio-input-extension-add"
              :isFullWidth="true"
              :classProp="'secondary'"
              @vasionButtonClicked="$refs.newFieldDialog.toggleAddNewField()"
            >
              Add New Field
            </VasionButton>
          </div>
        </div>
        <div class="col-xs-8 col-sm-9 col-md-10">
          <div class="md-layout-item vasion-flex-end">
            <VasionButton
              id="eform-back-to-form"
              :classProp="'text'"
              @vasionButtonClicked="backToLayouts()"
            >
              Back to {{ $layoutsLabelPlural }}
            </VasionButton>
            <VasionButton
              id="eform-preview"
              :classProp="'primary'"
              @vasionButtonClicked="togglePreview"
            >
              Preview
            </VasionButton>
            <VasionButton
              id="eform-save"
              :isDisabled="!isDirty"
              :classProp="'primary'"
              @vasionButtonClicked="save()"
            >
              Save
            </VasionButton>
          </div>
        </div>
        <div class="tabs">
          <VasionButton id="tab-settings" :classProp="calculatedClass('build')" @vasionButtonClicked="updateSelectedTab('build')">
            Build
          </VasionButton>
          <VasionButton id="tab-logic" :classProp="calculatedClass('settings')" @vasionButtonClicked="updateSelectedTab('settings')">
            Settings
          </VasionButton>
        </div>
      </div>
      <div v-show="selectedTabName === 'build'">
        <Loading
          class="vasion-loading-indicator"
          :active.sync="isLoading"
          :is-full-page="false"
          :color="loaderColor"
          loader="dots"
          :background-color="loaderBackgroundColor"
        />
        <div class="scroll-me">
          <div class="header-title">
            <div v-show="selectedTabName === 'build'" class="custom-logo-extension">
              <div class="header-container">
                <VasionButton
                  v-if="!isDocumentLoaded"
                  id="upload-header-image"
                  :classProp="'secondary'"
                  @vasionButtonClicked="showUploadDialog = !showUploadDialog"
                >
                  Upload Header Image
                </VasionButton>
                <img v-if="isDocumentLoaded" class="header-image" :src="localHeaderImage">
              </div>
              <div v-if="isDocumentLoaded" class="header-icon-buttons">
                <md-button
                  id="editIconBtn"
                  class="md-dense action-button highlightBackground"
                  title="Edit Image"
                  @click="editHeaderImage"
                >
                  <VasionEditIcon />
                </md-button>
                <md-button
                  id="deleteIconBtn"
                  class="md-dense action-button highlightBackground"
                  title="Remove Image"
                  @click="deleteHeaderImage"
                >
                  <VasionExIcon />
                </md-button>
              </div>
            </div>
            <div v-if="selectedTabName === 'build'" class="formio-extension">
              <input
                id="form-name"
                v-model="saveEFormsPayload.Name"
                autocomplete="off"
                class="form-name"
                inputType="top-white"
                name="form-name"
                :placeholder="`Untitled ${$layoutsLabel}`"
                @input="markAsDirty()"
              >
              <input
                id="form-description"
                v-model="saveEFormsPayload.Description"
                autocomplete="off"
                class="form-description"
                inputType="top-white"
                name="form-description"
                :placeholder="`${$layoutsLabel} Description`"
                @input="markAsDirty()"
              >
              <div class="header-icon-buttons">
                <VasionDropList
                  id="form-type-dropdown"
                  v-slot="slotItem"
                  v-model="selectedFormType"
                  :dataArray="formTypes"
                  title="Form Type"
                  name="form-type-dropdown"
                  width="200"
                  valueName="value"
                  displayName="name"
                  placeholder=""
                  :showSearchField="false"
                  :openAbove="true"
                  @input="selectedFormTypeChanged"
                >
                  {{ slotItem.item.name }}
                </VasionDropList>
                <div v-if="selectedFormType.value === 'pdf'" id="upload-pdf-div">
                  <VasionButton
                    id="pdf-settings"
                    class="btn-pdf-settings"
                    :classProp="'secondary'"
                    name="pdf-settings"
                    title="PDF Settings"
                    :icon="'VasionSettingsIcon'"
                    @vasionButtonClicked="showPDFFormSettings = true"
                  />
                  <VasionButton
                    id="upload-pdf-button"
                    :classProp="'secondary'"
                    @vasionButtonClicked="changePDFFile"
                  >
                    {{ pdfLoaded ? "Replace PDF" : "Upload PDF" }}
                  </VasionButton>
                </div>
              </div>
            </div>
          </div>
          <div
            v-if="showForm"
            :id="isMultiPageForm ? 'vasion-multipage-form-builder' : 'vasion-form-builder'"
            :class="{ 'pdf-builder-container': selectedFormType.value === 'pdf' }"
          >
            <formbuilder
              v-if="((selectedFormType.value !== 'pdf' || pdfLoaded) || (isCopy && selectedFormType.value === 'pdf' && newFileIsAvailable))"
              ref="formBuilder"
              :key="builderKey"
              :form="activeFormIoJSON"
              :options="config"
              @change="onFormBuilderChange"
              @removeComponent="componentRemoved"
              @addComponent="componentAdded"
              @saveComponent="componentSaved"
            />
          </div>
        </div>
      </div>
      <div v-show="selectedTabName === 'settings'" class="map-forms">
        <div class="vasion-settings-card">
          <div class="vasion-flex">
            <VasionDropList
              id="database-lookup-dropdown"
              v-slot="slotItem"
              v-model="databaseLookups"
              :dataArray="lookupList"
              :showIfEmpty="true"
              title="Database Lookup"
              name="database-lookup-dropdown"
              width="306"
              valueName="id"
              displayName="name"
              placeholder="Select a Database Lookup..."
              type="check-list"
              @input="markAsDirty()"
            >
              {{ slotItem.item.name }}
            </VasionDropList>
            <VasionCheckbox
              id="show-lookup-button-checkbox"
              class="row-checkbox"
              name="show-lookup-button-checkbox"
              :isDisabled="databaseLookups.find(x => x.id === 0) || databaseLookups.length < 1 ? true : false"
              :checked="saveEFormsPayload.ShowLookupButton"
              @disabled="saveEFormsPayload.ShowLookupButton = false"
              @change="saveEFormsPayload.ShowLookupButton = !saveEFormsPayload.ShowLookupButton; markAsDirty()"
            >
              Show Lookup Button
            </VasionCheckbox>
          </div>
          <div class="vasion-flex">
            <VasionCheckbox
              id="allow-file-upload"
              class="form-builder-margin-10 fixedWidth"
              name="allow-file-upload"
              :checked="saveEFormsPayload.AllowUploadFiles"
              @change="allowFileUploadChanged"
            >
              Allow File Upload
            </VasionCheckbox>
            <VasionCheckbox
              id="require-file-upload"
              class="form-builder-margin-10 fixedWidth"
              name="require-file-upload"
              :checked="saveEFormsPayload.RequireUploadFiles"
              @change="requireUploadFilesChanged"
            >
              Require File Upload
            </VasionCheckbox>
            <VasionButton
              v-if="attachmentSettingsAvailable"
              id="eform-manage-attachment-settings"
              :isDisabled="false"
              :classProp="'primary'"
              @vasionButtonClicked="toggleAttachmentSettingsDialog"
            >
              Manage Attachment Settings
            </VasionButton>
          </div>
          <div v-if="saveEFormsPayload.AllowUploadFiles || saveEFormsPayload.RequireUploadFiles" class="vasion-flex browse-folder-padding">
            <VasionInput
              v-model="saveEFormsPayload.AttachmentFolderName"
              title="ATTACHMENTS FOLDER"
              inputType="top-white"
              name="attachments-destination"
              placeholder="Select Folder..."
              :width="'306'"
              :isDisabled="true"
            />
            <VasionButton
              id="browse-folders-for-eform"
              :classProp="'primary'"
              @vasionButtonClicked="toggleShowBrowseFoldersDialog(); setFolderDialogCalledFrom('Attachments Folder')"
            >
              Browse
            </VasionButton>
          </div>
          <VasionCheckbox
            v-if="selectedFormType.value !== 'pdf'"
            id="require-user-signature"
            class="form-builder-margin-10 fixedWidth"
            name="require-user-signature"
            :checked="saveEFormsPayload.RequiresSignature"
            @change="saveEFormsPayload.RequiresSignature = !saveEFormsPayload.RequiresSignature; markAsDirty()"
          >
            Requires User Signature
          </VasionCheckbox>
          <VasionInput
            v-if="saveEFormsPayload.RequiresSignature"
            id="requires-signature-label"
            v-model="saveEFormsPayload.SignatureLabel"
            class="input-style"
            inputType="title-input"
            width="396"
            :title="'Signature Label'"
            :maxLengthProp="50"
            :placeholder="`Enter Signature Label...`"
            @input="markAsDirty()"
          />
          <VasionCheckbox
            id="generate-form-pdf"
            class="form-builder-margin-10 fixedWidth"
            name="generate-form-pdf"
            :checked="saveEFormsPayload.GenerateFormPdf"
            @change="generateFormPdfChanged"
          >
            Generate Form PDF
          </VasionCheckbox>
          <div v-if="saveEFormsPayload.GenerateFormPdf" class="vasion-flex">
            <VasionDropList
              id="form-pdf-destination"
              v-slot="slotItem"
              v-model="selectedFormPDFDestination"
              :dataArray="formPDFDestinationOptions"
              title="Form PDF Destination"
              name="form-PDF-destination"
              width="200"
              valueName="id"
              displayName="value"
              placeholder="None"
              :showSearchField="false"
              :openAbove="true"
              @input="setEFormBuilderIsDirty"
            >
              {{ slotItem.item.value }}
            </VasionDropList>
            <div v-if="selectedFormPDFDestination.value === 'Storage'" class="vasion-flex destination-side-padding">
              <VasionInput
                v-model="saveEFormsPayload.FolderName"
                title="DESTINATION FOLDER"
                inputType="top-white"
                name="destination-folder"
                placeholder="Select Folder..."
                :width="'306'"
                :isDisabled="true"
              />
              <VasionButton
                id="browse-folders-for-eform"
                :classProp="'primary'"
                @vasionButtonClicked="toggleShowBrowseFoldersDialog(); setFolderDialogCalledFrom('Destination Folder')"
              >
                Browse
              </VasionButton>
            </div>
            <div v-if="selectedFormPDFDestination.value === 'Workflow'" class="destination-side-padding">
              <VasionDropList
                v-slot="slotItem"
                v-model="selectedWorkflow"
                :dataArray="workflowList"
                width="396"
                title="WORKFLOW"
                placeholder="Select Workflow..."
                type="plain-list"
                displayName="name"
                valueName="value"
                @input="markAsDirty()"
              >
                {{ slotItem.item.name }}
              </VasionDropList>
            </div>
            <VasionCheckbox
              v-if="isMultiPageForm"
              id="pdf-page-break"
              class="form-builder-margin-10 fixedWidth"
              name="pdf-page-break"
              :checked="saveEFormsPayload.PdfPageBreak"
              @change="pdfPageBreakChanged"
            >
              Generate Separate Pages
            </VasionCheckbox>
          </div>

          <VasionCheckbox
            id="auto-save-drafts"
            name="auto-save-drafts"
            class="form-builder-margin-10 fixedWidth"
            :checked="saveEFormsPayload.IsAutoSaved"
            @change="saveEFormsPayload.IsAutoSaved = !saveEFormsPayload.IsAutoSaved; markAsDirty()"
          >
            Auto Save Drafts
          </VasionCheckbox>

          <VasionCheckbox
            id="submit-form-anonymously"
            name="submit-form-anonymously"
            class="form-builder-margin-10 fixedWidth"
            :checked="saveEFormsPayload.IsAnonymous"
            @change="saveEFormsPayload.IsAnonymous = !saveEFormsPayload.IsAnonymous; markAsDirty()"
          >
            Submit Form Anonymously
          </VasionCheckbox>

          <div class="collapsible-section">
            <VasionButton
              name="collapse-button"
              class="collapse-button"
              :classProp="'secondary-light'"
              :icon="showFormLayoutAccess ? 'VasionArrowDropDown16Icon' : 'VasionArrowDropDownRight16Icon'"
              title="Collapse Button"
              @vasionButtonClicked="showFormLayoutAccess = !showFormLayoutAccess"
            />
            <h2 class="subheader">
              {{ $layoutsLabel }} Access
            </h2>
          </div>
          <div v-show="showFormLayoutAccess">
            <VasionCheckbox
              id="allow-for-public-access"
              class="form-builder-margin-10"
              name="allow-for-public-access"
              :checked="saveEFormsPayload.PublicAccessible"
              @change="publicAccessChanged"
            >
              Allow For Public Access
            </VasionCheckbox>
            <p class="description-text">
              Public access will allow for anyone with the link to view the {{ $formsLabel.toLowerCase() }}'s {{ $layoutsLabel.toLowerCase() }}.
            </p>
            <div v-if="!saveEFormsPayload.PublicAccessible">
              <div>
                <VasionListSelection
                  :available-fields.sync="availableUsers"
                  :selected-fields.sync="assignedUsers"
                  :allow-field-ordering="false"
                  :available-label-text="'All Users'"
                  :selected-label-text="'Permitted Users'"
                  :unique-id="'permissions-users'"
                  @update:availableFields="setEFormBuilderIsDirty"
                  @update:selectedFields="setEFormBuilderIsDirty"
                  @update:addAll="setEFormBuilderIsDirty"
                  @update:removeAll="setEFormBuilderIsDirty"
                />
              </div>
              <div>
                <VasionListSelection
                  :available-fields.sync="availableGroups"
                  :selected-fields.sync="assignedGroups"
                  :allow-field-ordering="false"
                  :available-label-text="'All Groups'"
                  :selected-label-text="'Permitted Groups'"
                  :unique-id="'permissions-groups'"
                  @update:availableFields="setEFormBuilderIsDirty"
                  @update:selectedFields="setEFormBuilderIsDirty"
                  @update:addAll="setEFormBuilderIsDirty"
                  @update:removeAll="setEFormBuilderIsDirty"
                />
              </div>
            </div>
          </div>

          <div v-if="createTempDocsByFormID" class="temp-list-selection-styles">
            <VasionListSelection
              :available-fields.sync="availableSelections"
              :selected-fields.sync="selectedTemplates"
              :allow-field-ordering="false"
              :available-label-text="'Available Document Templates'"
              :selected-label-text="'Selected'"
              :unique-id="'templates-selection'"
              @update:availableFields="setEFormBuilderIsDirty"
              @update:selectedFields="setEFormBuilderIsDirty"
              @update:addAll="setEFormBuilderIsDirty"
              @update:removeAll="setEFormBuilderIsDirty"
            />
          </div>
        </div>
      </div>
      <VasionGeneralModal
        :rejectButtonText="'CANCEL'"
        :confirmButtonText="'OK'"
        :confirmButtonDisabled="!folderSelected"
        :modalType="'slot'"
        :sync="showBrowseFoldersDialog"
        @confirmButtonClick="folderDialogOK"
        @noButtonClick="handleCancel"
      >
        <div class="folder-dialog-browse">
          <VasionFolders
            :disableRootSelect="true"
            :disableUploadDocuments="true"
            @itemSelected="handleItemSelected"
          />
        </div>
      </VasionGeneralModal>
      <VasionFileUploadDialog
        :serverOptions="serverOptionsPdfFile"
        :show.sync="showUploadPDFDialog"
        :acceptedFileTypes="['.pdf']"
      />
      <VasionGeneralModal
        :rejectButtonText="'CANCEL'"
        :confirmButtonText="'CONTINUE'"
        :modalType="'slot'"
        :sync="showChangePDFSaveDialog"
        modalTitle="Changing Form Type"
        :buttonGroup="'center'"
        @confirmButtonClick="pdfLoaded = false; showChangePDFSaveDialog = false; save()"
        @noButtonClick="showChangePDFSaveDialog = false"
      >
        <div>
          This form has already been saved as type "PDF".
          <br>
          Would you like to continue changing the form type and delete the PDF file?
        </div>
      </VasionGeneralModal>
      <VasionGeneralModal
        :rejectButtonText="'Cancel'"
        :confirmButtonText="'OK'"
        :modalType="'slot'"
        :sync="showFormTypeChangeWarning"
        modalTitle="Changing Form Type to PDF"
        :buttonGroup="'center'"
        @confirmButtonClick="confirmChangeFormTypeToPdf"
        @noButtonClick="cancelChangeFormTypeToPdf"
      >
        <p class="warning-message">
          The form type was changed to "PDF". The "Requires User Signature" checkbox on the settings page is not supported with PDF forms. This will be disabled and unchecked. If you want to add a signature field to the PDF form, please drag and drop a signature field onto the PDF Form.
        </p>
      </VasionGeneralModal>
      <VasionGeneralModal
        v-show="showAttachmentSettings"
        id="attachment-settings-dialog"
        modalType="slot"
        rejectButtonText="Cancel"
        confirmButtonText="Done"
        :modalTitle="'Manage Attachment Settings'"
        :sync="true"
        :showTopRightCloseButton="true"
        @noButtonClick="cancelAttachmentSettings"
        @confirmButtonClick="doneAttachmentSettings"
      >
        <div class="attachment-settings-container">
          <VasionInput
            id="attachment-size-limit"
            v-model="attachmentSizeLimit"
            name="max-file-size"
            title="Attachment Size Limit (MB)"
            class="input-style"
            inputType="top-white"
            width="100%"
            type="text"
            specialType="number"
            :minValue="0"
            :maxLengthProp="7"
            @change="onAttachmentSizeLimitChanged"
          />
          <VasionDropList
            v-slot="slotItem"
            v-model="selectedFileExtensions"
            class="droplist mt-4"
            :overlayList="true"
            :dataArray="fileExtensionList"
            width="100%"
            title="Allowed File Type"
            placeholder="Select file types..."
            type="check-list"
            displayName="name"
            valueName="value"
            @input="onSelectedFileExtensionsChanged"
          >
            {{ slotItem.item.name }}
          </VasionDropList>
          <div
            v-if="showCustomFileExtensionsField"
          >
            <VasionInput
              id="custom-file-extensions"
              v-model="customFileExtensions"
              class="input-style"
              inputType="top-white"
              width="100%"
              title="Custom File Types"
              name="template-name"
              oninput="this.value = String(this.value).toUpperCase().replace('.' , '').replace(' ' , '')"
              @change="onCustomFileExtensionsChanged"
            />
            <span class="input-note">Separate multiple file types with commas</span>
          </div>
        </div>
      </VasionGeneralModal>

      <VasionGeneralModal
        id="change-pdf-confirmation-div"
        :confirmButtonText="'CONFIRM'"
        :rejectButtonText="'CANCEL'"
        :modalType="'slot'"
        :sync="showReplaceFileDialog"
        @confirmButtonClick="replaceFileOKClick()"
        @noButtonClick="toggleReplaceFileDialog()"
      >
        <div v-if="isDirty" class="vasion-error-text">
          WARNING: Any changes you have not saved will be lost
        </div>
        <div>
          <md-radio
            v-model="clearZonesOnChangeFile"
            :value="false"
          >
            Replace file only (keep existing zones)
          </md-radio>
        </div>
        <div>
          <md-radio
            v-model="clearZonesOnChangeFile"
            :value="true"
          >
            Replace file and clear zones
          </md-radio>
        </div>
      </VasionGeneralModal>

      <VasionGeneralModal
        id="replace-pdf-confirmation-div"
        :confirmButtonText="'REPLACE'"
        :rejectButtonText="'CANCEL'"
        :modalType="'message'"
        :buttonGroup="'center'"
        :message="replaceMessage + '\n Would you like to proceed and force replacing the file?'"
        :sync="showForceReplaceDialog"
        @confirmButtonClick="replacePDFModalOKClick()"
        @noButtonClick="toggleForceReplaceDialog()"
      />

      <PDFFormSettingsForm
        :showPDFFormSettings="showPDFFormSettings"
        :data="pdfSettings"
        @save="savePDFFormSettings"
        @close="showPDFFormSettings = false"
      />

      <VasionSnackbar
        id="workflow-started-snack"
        :showRawHtml="showRawHtml"
        :showSnackbarBool.sync="showSnackbarBool"
        :snackbarImage="snackbarImage"
        :snackbarSubTitle="snackbarSubTitle"
        :snackbarTitle="snackbarTitle"
      />
      <NewFieldDialog
        ref="newFieldDialog"
        :builderMode="true"
        :config="newFieldConfig"
        :fieldTypeList="fieldTypeList"
        :fields.sync="fields"
        @saved="newFieldAdded()"
      />
      <md-dialog id="upload-new-file-dialog" class="dialog" :md-active.sync="showUploadDialog" :md-click-outside-to-close="false">
        <div class="upload-container">
          <h4>Recommended image size: 200w x 50h max size</h4>
          <div class="file-pond-block-wrapper">
            <file-pond ref="pond" class="new-document" :server="serverOptionsHeaderImage" />
          </div>
          <VasionButton
            id="close-upload-dialog"
            :isFullWidth="false"
            :classProp="'secondary'"
            @vasionButtonClicked="showUploadDialog = !showUploadDialog"
          >
            CLOSE
          </VasionButton>
        </div>
      </md-dialog>
    </div>
    <div v-if="showPreview">
      <div class="md-layout-item vasion-flex-end formio-preview-header">
        <VasionButton
          id="eform-hide-preview"
          :classProp="'primary'"
          @vasionButtonClicked="togglePreview"
        >
          Hide Preview
        </VasionButton>
        <VasionButton
          id="btnFullWidth"
          name="btnFullWidth"
          class="full-width-btn"
          :title="`Turn Full Width ${fullWidthWrapperEnabled ? 'Off' : 'On'}`"
          :icon="fullWidthWrapperEnabled ? 'VasionExitFullscreenIcon' : 'VasionFullscreenIcon'"
          @vasionButtonClicked="toggleFullwidhtContainer"
        />
      </div>
      <div class="form-container">
        <div
          :class="{ 'full-width': fullWidthWrapperEnabled, 'form-wrapper' : true }"
        >
          <StartWorkflowForm
            :formLayout="eFormPreview"
            :workflowID="eFormPreview.workflowID"
            :inPreviewMode="true"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Loading from 'vue-loading-overlay';
import { FormBuilder } from 'vue-formio';
import autoScroll from 'dom-autoscroller';
import { builderConfig } from '@/assets/js/formIOConfig'
import NewFieldDialog from '@/components/forms/NewFieldDialog.vue';
import { loaderBackgroundColor, loaderColor } from '@/assets/js/styleConfig'
import { addTotalLogicDropList, setCalculateValueForTotalColumn } from '@/store/helperModules/forms.module'
import { toBase64 } from '@/store/helperModules/storage.module'
import StartWorkflowForm from '@/components/workflow/StartWorkflowForm.vue'
import cloneDeep from 'lodash/cloneDeep'
import PDFFormSettingsForm from '@/components/forms/PDFFormSettingsForm.vue';

import { flattenComponents } from 'formiojs/utils/formUtils.js'
import 'bootstrap/dist/js/bootstrap.min.js'

export default {
  name: 'FormioFormBuilder',
  components: {
    formbuilder: FormBuilder,
    Loading,
    NewFieldDialog,
    StartWorkflowForm,
    PDFFormSettingsForm
  },
  props: {
    eForm: {
      type: Object,
      required: false,
      default: null,
    },
    isCopy: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      activeFormIoJSON: {},
      assignedGroups: [],
      assignedUsers: [],
      attachmentSizeLimit: '',
      attachmentSizeLimitInitialState: '',
      availableGroups: [],
      availableSelections: [],
      availableUsers: [],
      builderKey: 1,
      checkedDocumentsString: '1',
      chosenFolderID: '',
      clearZonesOnChangeFile: false,      
      config: {},
      customFileExtensions: '',
      customFileExtensionsArrayBuffer : [],
      customFileExtensionsInitialState: '',
      databaseLookups: [],
      defaultTemplateMappingSave: {
        EFormID: this.isCopy ? 0 : this.eForm.EFormID,
        DocumentTemplateIDs: [],
      },
      eFormBuilderIsDirty: false,
      errorMessages: [],
      featureFlagPdfForm: false,
      fields: [],
      fieldSearchInput: '',
      fieldTypeList: [
        { id: 1, value: 'Textbox' },
        { id: 2, value: 'Dropdown' },
        { id: 3, value: 'Date' },
        { id: 6, value: 'Number' },
        { id: 7, value: 'URL' },
        { id: 8, value: 'Email' },
        { id: 9, value: 'Checkbox' },
        { id: 10, value: 'Radio Button' },
        { id: 11, value: 'Currency' },
        { id: 12, value: 'Signature Field' },
        { id: 13, value: 'Text Area' },
        { id: 14, value: 'Address' },
      ],
      fileExtensionList: [],
      fileExtensionsInitialState: '',
      folderDialogCalledFrom: '',
      folderSelected: false,
      formDestinationFolder: '',
      formIsDirty: false,
      formPDFDestinationOptions: [
        { id: 1, value: 'Storage' },
        { id: 2, value: 'Workflow' },
      ],
      formSelected: true,
      formTypes:[
        {
          name: 'Form',
          value: 'form'
        },
        {
          name: 'Wizard',
          value: 'wizard'
        },
      ],
      fullWidthWrapperEnabled: false,
      hasNumPagesError: false,
      hasPageSizeError: false,
      intialSelectedDocTemplates: [],
      isDocumentLoaded: false,
      isLoading: false,
      isReplacingFile: false,
      lastSelectedFormType: {
          name: 'Form',
          value: 'form'
      },
      latestPDFData: {},
      loaderBackgroundColor: loaderBackgroundColor,
      loaderColor: loaderColor,
      localHeaderImage: '',
      newFieldConfig: {
        allowMultiples: true,
        fieldUniqueName: { name: '<New Field>' },
        fieldLabel: '',
        fieldType: {},
        fieldLength: 100,
        fieldValues: '',
        fieldHidden: false,
        mandatory: false,
        readOnly: false,
        totalPageNo: false,
        systemDate: false,
        filterValues: false,
        filterFieldsList: [],
      },
      newFileIsAvailable: false,
      pdfLoaded: false,
      replaceMessage: '',
      replacePDFData: {},
      saveEFormsPayload: this.eForm,
      selectedFileExtensions: [],
      selectedFileExtensionsArrayBuffer: [],
      selectedFileExtensionsInitialState: [],
      selectedFolder: {},
      selectedFormType: {
          name: 'Form',
          value: 'form'
      },
      selectedFormPDFDestination: {
        id: null,
        value: null,
      },
      selectedTabName: 'build',
      selectedTemplates: [],
      selectedWorkflow: { name: '[None]', value: 0 },
      serverOptionsPdfFile: {
        process: this.processHandlerPdfFile,
      },
      serverOptionsHeaderImage: {
        process: this.processHandlerHeaderFile,
      },
      showAttachmentSettings: false,
      showBrowseFoldersDialog: false,
      showChangePDFSaveDialog: false,
      showCustomFileExtensionsField: false,
      showForceReplaceDialog: false,
      showForm: false,
      showFormLayoutAccess: false,
      showFormTypeChangeWarning: false,
      showPDFFormSettings: false,
      showPreview: false,
      showRawHtml: false,
      showReplaceFileDialog: false,
      showSnackbarBool: false,
      showUploadDialog: false,
      showUploadPDFDialog: false,
      snackbarImage: '',
      snackbarSubTitle: '',
      snackbarTitle: '',
      templateMappingSave: {
        EFormID: this.isCopy ? 0 : this.eForm.EFormID,
        FieldMappings: [],
      },
      uploadedFileDetail: '',
      uploadedfilePath: '',
      workflowList: [],
    }
  },
  computed: {
    allFileExtensions() {return this.$store.state.forms.fileExtensions},
    allWorkflowList() { return this.$store.state.workflow.workflowAdminList },
    attachmentSettingsAvailable () {
      return this.saveEFormsPayload.AllowUploadFiles || this.saveEFormsPayload.RequireUploadFiles
    },
    availableTemplatesGetter() { return this.$store.getters['forms/availableTemplates'] },
    builderConfigFromFormID() { return this.$store.state.forms.builderConfigFromFormID},
    createTempDocsByFormID: {
      get: function () { return this.$store.state.forms.createTempDocsByFormID?.length ? this.$store.state.forms.createTempDocsByFormID : null },
      set: function () { },
      cache: false,
    },
    eFormPreview() {
      const eFormClone = cloneDeep(this.eForm)

      eFormClone.FormIOJSON = JSON.stringify(this.activeFormIoJSON).replace('EFormPdf/', 'EformPdf/Preview_')
      eFormClone.Description = this.saveEFormsPayload.Description
      eFormClone.Name = this.saveEFormsPayload.Name?.length > 0 ? this.saveEFormsPayload.Name : 'Untitled Form'
      eFormClone.workflowID = this.selectedWorkflow?.value ?? 0
      eFormClone.HeaderImageBase64 = this.localHeaderImage

      return eFormClone
    },
    formHeaderImage() { return this.saveEFormsPayload.HeaderImageBase64 ? this.saveEFormsPayload.HeaderImageBase64 : '' },
    groups() {
      return this.$store.state.common.groups
    },
    indexFieldsForForm() { return this.$store.state.common.indexFields },
    isDirty() { return this.formIsDirty || this.eFormBuilderIsDirty },
    isMultiPageForm() { return this.selectedFormType.value === 'wizard' },
    isPdfForm() { return this.selectedFormType.value === 'pdf' },
    lookupList() {
      const filteredLookups = this.$store.state.fieldMapping.lookupList.filter(x => x.attributeFormID === this.$route.params.formID)
      return filteredLookups
    },
    pdfSettings() {
      return this.extractPdfSettings(['fontSize', 'layout', 'pageSize'], this.activeFormIoJSON) || {}
    },
    selectedTemplatesGetter() { return this.$store.getters['forms/selectedTemplates'] },
    users() {
      return this.$store.state.common.users
    },
  },
  watch: {
    attachmentSettingsAvailable() {
      if (this.attachmentSettingsAvailable) {
        this.attachmentSizeLimit = ''
        this.saveEFormsPayload.MaxFileSize = null
        this.selectedFileExtensions = []
        this.selectedFileExtensionsArrayBuffer = []
        this.customFileExtensions = ''
        this.customFileExtensionsArrayBuffer = []
        this.saveEFormsPayload.FileExtensions = ''
      }
    },
    availableTemplatesGetter() {
      this.availableSelections = this.availableTemplatesGetter
    },
    selectedTemplatesGetter() {
      this.selectedTemplates = this.selectedTemplatesGetter
    },
  },
  created: async function () {
    this.isLoading = true

    await Promise.all([
      this.$store.dispatch('common/getIndexFieldsForForm', this.$route.params.formID),
      this.$store.dispatch('common/getUsers', { includeExternalAPIUsers: true }),
      this.$store.dispatch('common/getGroups'),
      this.$store.dispatch('forms/getCreateTempDocsByFormID', this.$route.params.formID),
      this.$store.dispatch('forms/getCreateTempDocsByEFormID', this.$route.params.formLayoutID),
      this.$store.dispatch('workflow/getWorkflowAdminList'),
      this.$store.dispatch('forms/getFileExtensions'),
    ])
    this.activeFormIoJSON = this.saveEFormsPayload.FormIOJSON ? JSON.parse(this.saveEFormsPayload.FormIOJSON) : { display: 'form', components: [] }

    this.featureFlagPdfForm = this.$store.state.systemSettings.featureFlags.pdfFormsEnabled
    if (this.featureFlagPdfForm) {
      this.formTypes.push({name: 'PDF', value: 'pdf'})
    }
    this.selectedFormType = { ...this.formTypes.find(x => x.value === this.activeFormIoJSON.display) }
    this.lastSelectedFormType = this.selectedFormType
    await this.fetchCustomFormBuilderFields()
 
    this.intialSelectedDocTemplates = await this.$store.dispatch('forms/getCreateTempDocsForFormID', this.$route.params.formLayoutID)

    if (this.isCopy) {
      if (this.isPdfForm) {
        const payload = {
          Value: this.saveEFormsPayload.GUID,
        }
        this.saveEFormsPayload.GUID = ''
        try {
          const pdfData = await this.$store.dispatch('document/copyEFormPdf', payload)
          if (pdfData?.ResultStatus === 1 && pdfData?.formSettings?.pdf?.id) {
            this.saveEFormsPayload.GUID = pdfData.formSettings.pdf.id
            //here we update the formioJSON
            let formioJSON = cloneDeep(this.activeFormIoJSON)
            formioJSON.settings = pdfData.formSettings
            this.activeFormIoJSON = formioJSON
            
            this.newFileIsAvailable = true
          }else {
            this.snackbarTitle = 'Copy PDF Form error'
            this.snackbarSubTitle = pdfData?.ResultMessage ? pdfData.ResultMessage : 'Could not copy form'
            this.snackbarImage = false
            this.showSnackbarBool = true
            setTimeout(() => {
              this.backToLayouts()
            }, 3000)
          }
        } catch (error) {
          this.snackbarTitle = 'Copy PDF Form error'
          this.snackbarSubTitle = 'Could not copy form'
          this.snackbarImage = false
          this.showSnackbarBool = true
          setTimeout(() => {
            this.backToLayouts()
          }, 3000)
        }
      }
      this.saveEFormsPayload.Name = "Copy of - " + this.saveEFormsPayload.Name
      this.saveEFormsPayload.EFormID = 0
      if (this.saveEFormsPayload.FormFields) {
        this.saveEFormsPayload.FormFields.map((field) => { field.EFormFieldID = 0})
      }
    }

    this.workflowList = this.allWorkflowList.map((workflow) => {
      return {
        name: workflow.sName,
        value: workflow.iID,
      }
    })

    this.fileExtensionList = this.allFileExtensions.map((fileExtension) => {
      return {
        name: fileExtension,
        value: fileExtension,
      }
    })
    this.fileExtensionList.push({name: 'CUSTOM', value: 'CUSTOM'})

    if (this.eForm.WorkflowID !== null && this.eForm.WorkflowID > 0) {
      const tempWorkflow = this.workflowList.find(e => this.eForm.WorkflowID === e.value)

      if (tempWorkflow) {
        this.selectedWorkflow = tempWorkflow
      } else {
        // The selected workflow is not in the list, which is fine.  We just need to get the name
        const workflowSettingsData = await this.$store.dispatch('workflow/getWorkflowSettingsNoCommit', this.eForm.WorkflowID)
        if (workflowSettingsData && workflowSettingsData.wfID === this.eForm.WorkflowID) {
          this.selectedWorkflow = {
            name: workflowSettingsData.name,
            value: this.eForm.WorkflowID,
          }

          this.workflowList.unshift(this.selectedWorkflow)
        }
      }
    }

    this.workflowList.unshift({ name: '[None]', value: 0 })

    if (this.formHeaderImage) {
      this.localHeaderImage = this.formHeaderImage
      this.isDocumentLoaded = true
    }
    await this.$store.dispatch('fieldMapping/getDatabaseLookups', true)
    this.loadUsersAndGroups()

    this.saveEFormsPayload.PublicAccessible = this.eForm.PublicAccessible
    this.assignedUsers = this.saveEFormsPayload.AccessUserIDs.map(e => this.availableUsers.find(x => x.value === e))
    this.assignedGroups = this.saveEFormsPayload.AccessGroupIDs.map(e => this.availableGroups.find(x => x.value === e))
    this.availableUsers = this.availableUsers.filter(x => !this.assignedUsers.includes(x))
    this.availableGroups = this.availableGroups.filter(x => !this.assignedGroups.includes(x))

    this.saveEFormsPayload.IsAutoSaved = this.eForm.IsAutoSaved
    this.saveEFormsPayload.IsAnonymous = this.eForm.IsAnonymous 
    
    if (typeof this.eForm.DBLookupIDs == "object") {
      const tempLookups = this.lookupList.filter(x => {
        return x.id === this.eForm.DBLookupIDs.find(y => y === x.id)
      })
      if (tempLookups.length > 0) {
        this.databaseLookups = tempLookups
      }
    }

    this.attachmentSizeLimit =
      this.saveEFormsPayload.MaxFileSize !== null ? this.saveEFormsPayload.MaxFileSize : null

    await this.updateFileExtensionFields()
    this.attachmentSizeLimitInitialState = this.attachmentSizeLimit
    this.customFileExtensionsInitialState = this.customFileExtensions
    this.selectedFileExtensionsInitialState = [...this.selectedFileExtensions]
    this. fileExtensionsInitialState = this.saveEFormsPayload.FileExtensions

    if (this.eForm.WorkflowID) {
      this.selectedFormPDFDestination = this.formPDFDestinationOptions[1]
    } else if (this.eForm.FolderName) {
      this.selectedFormPDFDestination = this.formPDFDestinationOptions[0]
    }
    this.config = cloneDeep(builderConfig)
    this.updateBuilderConfig()
    this.removeCopyButton()

    if (this.isPdfForm) {
      this.pdfLoaded = true
    } else {
      autoScroll([
          window,
          document.querySelector('.scroll-me'),
        ], {
        margin: 20,
        maxSpeed: 15,
        scrollWhenOutside: true,
        autoScroll: function () {
            return this.down;
        },
      });
    }

    this.showForm = true
    this.isLoading = false
  },
  methods: {
    allowFileUploadChanged() {
      this.saveEFormsPayload.AllowUploadFiles = !this.saveEFormsPayload.AllowUploadFiles
      if(this.saveEFormsPayload.AllowUploadFiles) {
        this.saveEFormsPayload.RequireUploadFiles = false
      } else {
        this.clearAttachmentsFolder()
      }
      this.markAsDirty()
    },
    async backToLayouts() {
      await this.$store.dispatch('forms/setDefaultFormTab', 'layouts')
      this.$router.push({ name: 'EditForm', params: { formID: this.$route.params.formID } })
    },
    calculatedClass(selectedTab) {
      return this.selectedTabName === selectedTab ? 'tab-selected' : 'tab'
    },
    cancelAttachmentSettings() {
      this.showAttachmentSettings = false;
      this.attachmentSizeLimit = this.attachmentSizeLimitInitialState
      this.customFileExtensions = this.customFileExtensionsInitialState
      this.selectedFileExtensions = [...this.selectedFileExtensionsInitialState]
      this.saveEFormsPayload.MaxFileSize = this.attachmentSizeLimitInitialState
      this.saveEFormsPayload.FileExtensions = this.fileExtensionsInitialState
    },
    cancelChangeFormTypeToPdf() {
      this.showFormTypeChangeWarning = false
      this.selectedFormType = this.lastSelectedFormType
      this.selectedFormTypeChanged(this.selectedFormType)
    },
    changePDFFile() {
      this.isReplacingFile = true
      this.toggleReplaceFileDialog()
    },
    clearAttachmentsFolder() {
      this.saveEFormsPayload.AttachmentFolderId = null
      this.saveEFormsPayload.AttachmentFolderName = ''
    },
    //used for $ref:
    clearTemplateSelectionOptions() {
      this.availableSelections = []
      this.selectedTemplates = []
    },
    componentAdded(component) {
      const vasionUniqueFieldName = component.vasionUniqueFieldName
      if (vasionUniqueFieldName){
        if (vasionUniqueFieldName?.length > 0) {
          const flattenedComponents = flattenComponents(this.activeFormIoJSON.components, true)
          for (const key in flattenedComponents) {
            const isMatchingVasionField = 
              key !== component.key
              && flattenedComponents[key].vasionUniqueFieldName === vasionUniqueFieldName
              && !key.includes('.') //flattenComponents will create keys like 'dataGrid.fieldKey'
            
            if(isMatchingVasionField) {
              component.calculateValue = `value = data['${key}']`
              component.vasionUniqueFieldName = ''
              component.vasionFieldName = `Linked to ${component.vasionFieldName}`
              component.disabled = true
              break
            }
          }
        }
      }
      if (["textfield","textarea","email","number","phoneNumber","currency"].includes(component.type)){
        component.autocomplete = "off"
      }

      if (this.isPdfForm) {
        this.setFieldDefaultSize(component)
        
      }

      if (!this.isPdfForm && (component.type === 'number' || component.type === 'currency') ) {
        this.updateBuilderConfig()
      }
      this.removeCopyButton()
    },
    componentRemoved(component) {
      if (!this.isPdfForm && (component.type === 'datagrid' || component.type === 'number' || component.type === 'currency')) {
        this.updateBuilderConfig()
      }
      this.removeCopyButton()
    },
    componentSaved(component) {
      if (!this.isPdfForm) {
        if (component.type === 'datagrid' || component.type === 'number' || component.type === 'currency') {
          this.updateBuilderConfig()
        }
        setCalculateValueForTotalColumn(component)
      }
      this.removeCopyButton()
    },
    confirmChangeFormTypeToPdf() {
      this.showFormTypeChangeWarning = false
      this.showUploadPDFDialog = true
      this.saveEFormsPayload.RequiresSignature = false
      this.selectedFormTypeChanged(this.selectedFormType)
    },
    deleteHeaderImage() {
      this.localHeaderImage = ''
      this.isDocumentLoaded = false
      this.markAsDirty()
    },
    doneAttachmentSettings() {
      if (this.attachmentSizeLimit > 1048576) {
        this.snackbarTitle = 'Attachment Settings error '
        this.snackbarSubTitle = 'The field limit is 1,048,576 MB'
        this.snackbarImage = false
        this.showSnackbarBool = true
        document.getElementById("attachment-size-limit").focus();
        return
      }
      this.showAttachmentSettings = false;
    },
    editHeaderImage() {
      this.showUploadDialog = !this.showUploadDialog
    },
    extractPdfSettings(settingNames, formIoJSON) {
      if(!settingNames) return
      if(!formIoJSON.settings) return
      if (Object.keys(formIoJSON.settings).length < 1) return

      let pdfSettings = {}
      for (let [key, value] of Object.entries(formIoJSON.settings)) {
        if (!settingNames.includes(key)) continue
        pdfSettings[key] = value
      }
      return pdfSettings
    },
    async fetchCustomFormBuilderFields() {
      await this.$store.dispatch('forms/getFormBuilderConfigByFormID', {formID: this.$route.params.formID, formType: this.selectedFormType.value})
    },
    folderDialogOK() {
      if (this.folderSelected) {
        this.toggleShowBrowseFoldersDialog()
      }
    },
    generateFormPdfChanged() {
      this.saveEFormsPayload.GenerateFormPdf = !this.saveEFormsPayload.GenerateFormPdf
      this.setEFormBuilderIsDirty()
    },
    handleCancel() {
      this.folderSelected = false
      this.toggleShowBrowseFoldersDialog()
    },
    handleItemSelected(item) {
      if (this.folderDialogCalledFrom === 'Destination Folder') {
        this.folderSelected = true
        this.saveEFormsPayload.FolderID = item.value
        this.saveEFormsPayload.FolderName = item.name
      } else if (this.folderDialogCalledFrom === 'Attachments Folder') {
        this.folderSelected = true
        this.saveEFormsPayload.AttachmentFolderId = item.value
        this.saveEFormsPayload.AttachmentFolderName = item.name
      }
      this.markAsDirty()
    },
    loadUsersAndGroups() {
      this.availableUsers = this.users.map((user) => {
        return {
          name: user.name,
          value: user.value,
        }
      })
      this.availableGroups = this.groups.map((group) => {
        return {
          name: group.name,
          value: group.value,
        }
      })
    },
    async logFormSaved() {
      const hasDocTemplate = this.defaultTemplateMappingSave?.DocumentTemplateIDs?.length > 0 ? true : false
      const formDestination = (this.saveEFormsPayload.FolderID && this.saveEFormsPayload.FolderName) ? 'Storage' : 'Workflow'
      let properties = {
        isAutoSave: this.saveEFormsPayload.IsAutoSaved,
        IsAnonymous: this.saveEFormsPayload.IsAnonymous,
        isFormPublic: this.saveEFormsPayload.PublicAccessible,
        hasDocTemplate,
        formDestination,
      }
      await this.$amplitude.trackEvent('Form Saved', properties)
    },
    mapIndexToEform() {
      this.saveEFormsPayload.FormFields = this.saveEFormsPayload.FormFields.map((f) => {
        f.MVMappedFieldName = f.MVMappedFieldName?.value ? f.MVMappedFieldName.value : f.MVMappedFieldName
        return f
      })
    },
    async prepairDefaultTemplateMappingForSave() {
      this.defaultTemplateMappingSave.DocumentTemplateIDs = []
      this.selectedTemplates.map(t => {
        this.defaultTemplateMappingSave.DocumentTemplateIDs.push(t.id)
      })
    },
    markAsClean() {
      this.formIsDirty = false
      this.eFormBuilderIsDirty = false
      this.$emit('clean')
    },
    markAsDirty() {
      this.formIsDirty = true
      this.$emit('dirty')
    },
    mergeFileExtensions() {
      let fileExtensions = this.showCustomFileExtensionsField ?
        [...this.selectedFileExtensionsArrayBuffer, ...this.customFileExtensionsArrayBuffer] : this.selectedFileExtensionsArrayBuffer
      fileExtensions = Array.from(new Set(fileExtensions)).filter(String)
      this.saveEFormsPayload.FileExtensions = fileExtensions.join(",")
      this.markAsDirty()
      if (!this.showCustomFileExtensionsField) {
        this.customFileExtensions = ""
        this.customFileExtensionsArrayBuffer = []
      }
    },
    async newFieldAdded() {
      await this.fetchCustomFormBuilderFields()
      this.updateBuilderConfig(true)
      this.removeCopyButton()
    },
    onAttachmentSizeLimitChanged() {
      this.saveEFormsPayload.MaxFileSize !== this.attachmentSizeLimit ?
        this.markAsDirty() : this.markAsClean()
      this.saveEFormsPayload.MaxFileSize =
        this.attachmentSizeLimit > 0 ? Math.abs(this.attachmentSizeLimit) : null
    },
    onCustomFileExtensionsChanged() {
      this.customFileExtensionsArrayBuffer = []
      if (this.customFileExtensions.length > 0) {
        this.customFileExtensionsArrayBuffer = this.customFileExtensions.includes(',') ?
          this.customFileExtensions.split(',') : [this.customFileExtensions]
      }
      this.customFileExtensionsArrayBuffer = Array.from(
        new Set(this.customFileExtensionsArrayBuffer)).filter(String)
      this.mergeFileExtensions()
    },
    onFormBuilderChange(event) {
      if ('changed' in event) {
        if (event.changed === undefined) {
          return
        }
      }
      this.setEFormBuilderIsDirty()
    },
    onSelectedFileExtensionsChanged() {
      let customFileExtensionSelected = false
      this.selectedFileExtensionsArrayBuffer = []
      Object.values(this.selectedFileExtensions).forEach((selectedFileExtension) => {
        selectedFileExtension.value === "CUSTOM" ?
          customFileExtensionSelected = true : this.selectedFileExtensionsArrayBuffer.push(selectedFileExtension.value)
      })
      this.showCustomFileExtensionsField = customFileExtensionSelected
      this.mergeFileExtensions()
    },
    pdfPageBreakChanged() {
      this.saveEFormsPayload.PdfPageBreak = !this.saveEFormsPayload.PdfPageBreak
      this.setEFormBuilderIsDirty()
    },
    //eslint-disable-next-line
    processHandlerPdfFile: async function (fieldName, file, metadata, load, error, progress, abort) {
      try {
        if (!file.name.match(/.(pdf)$/i)) {
          this.snackbarTitle = 'Upload error'
          this.snackbarSubTitle = 'Only .pdf file types are supported'
          this.snackbarImage = false
          this.showSnackbarBool = true
          return
        }

        let fileToUpload = await toBase64(file);
        let pdfData = null
        const stringOffset = ";base64,";
        const indexOfStringOffset = fileToUpload.indexOf(stringOffset);
        if (indexOfStringOffset >= 0) {
          fileToUpload = fileToUpload.substring(
            indexOfStringOffset + stringOffset.length
          );
        }

        if (this.isReplacingFile) {
          const payload = {
            fileData: fileToUpload,
            formGUID: this.saveEFormsPayload.GUID,
            deleteZones: this.clearZonesOnChangeFile
          }
          
          pdfData = await this.$store.dispatch('forms/replaceFormIOPdf', payload)
          if (!pdfData?.formSettings?.pdf?.id || pdfData?.ResultStatus !== 1) {
            this.snackbarTitle = 'Upload error'
            this.snackbarSubTitle = `${pdfData?.ResultMessage ? pdfData?.ResultMessage: ''}. Please contact your administrator`
            this.snackbarImage = false
            this.showSnackbarBool = true
            
            return
          }

          if (pdfData.PagesDifferent || pdfData.SizesDifferent) {
            this.showForceReplaceDialog = true
            this.replaceMessage = pdfData.Error + (pdfData.SizesDifferent ? 'This will result in components being moved to the upper-left corner of the page.' : '')
            this.replacePDFData = pdfData.formSettings
            this.hasNumPagesError = pdfData.PagesDifferent
            this.hasPageSizeError = pdfData.SizesDifferent
            return
          }
        } else {
          const payload = {
            fileData: fileToUpload,
            formGUID: this.saveEFormsPayload.GUID,
          }
          
          pdfData = await this.$store.dispatch('forms/uploadFormIOPdf', payload)
          if (!pdfData?.formSettings?.pdf?.id || pdfData?.ResultStatus !== 1) {
            this.snackbarTitle = 'Upload error'
            this.snackbarSubTitle = `${pdfData?.ResultMessage ? pdfData?.ResultMessage: ''}. Please contact your administrator`
            this.snackbarImage = false
            this.showSnackbarBool = true
            
            return
          }
        }

        progress(true, 0, 1024);
        load(file.name)

        this.saveEFormsPayload.GUID = pdfData.formSettings.pdf.id
        //here we update the formioJSON
        let formioJSON = cloneDeep(this.activeFormIoJSON)
        formioJSON.settings = pdfData.formSettings
 
        this.latestPDFData = pdfData.formSettings
        // Logic for replacing a file and clearing the components (we need to have something in the component array, otherwise the PDF breaks)
        if (this.isReplacingFile && this.clearZonesOnChangeFile) {
          this.isReplacingFile = false
          formioJSON.components = []
          this.setEFormBuilderIsDirty()
        }
        this.activeFormIoJSON = formioJSON
        
        this.pdfLoaded = true
        this.updateBuilderConfig(true)
        this.markAsDirty()
        return {
          abort: () => {
            if (abort !== undefined) {
              abort();
            }
          },
        };
      } catch (err) {
        console.warn(err)
      } finally {
        this.showUploadPDFDialog = false
      }
    },
    // eslint-disable-next-line
    processHandlerHeaderFile: async function (fieldName, file, metadata, load, error, progress, abort) {
      try {
          if (!file.name.match(/.(jpg|jpeg|png|bmp)$/i)) {
            this.snackbarTitle = 'Upload error'
            this.snackbarSubTitle = 'Only .png, .jpg, .jpeg, or .bmp file types are supported'
            this.snackbarImage = false
            this.showSnackbarBool = true;
          } else {
            this.localHeaderImage = await toBase64(file)
            this.isDocumentLoaded = true
          }

          progress(true, 0, 1024);
          load(file.name)
          this.showUploadDialog = false
          this.markAsDirty()
        return {
          abort: () => {
            if (abort !== undefined) {
              abort();
            }
          },
        };
      } catch (err) {
        console.warn(err)
      }
    },
    publicAccessChanged() {
      this.saveEFormsPayload.PublicAccessible = !this.saveEFormsPayload.PublicAccessible
      this.markAsDirty()
    },
    requireUploadFilesChanged() {
      this.saveEFormsPayload.RequireUploadFiles = !this.saveEFormsPayload.RequireUploadFiles
      if (this.saveEFormsPayload.RequireUploadFiles) {
        this.saveEFormsPayload.AllowUploadFiles = false
      } else {
        this.clearAttachmentsFolder()
      }
      this.markAsDirty()
    },
    removeCopyButton() {
      setTimeout(() => {
        const components = document.getElementsByClassName('builder-component')
        for (let i = 0; i < components.length; i++) {
          if(components[i]?.formioComponent.component.action === "submit") {
            const buttons = components[i]?.childNodes[1]?.childNodes
            if (buttons === undefined) continue
            for (let j = 0; j < buttons.length; j++) {
              const button = buttons[j]
              if (button.nodeType == 3) continue // Skip text nodes
              if (button.classList.contains('component-settings-button-remove')) {
                button.classList.add('hide')
              }
            }
          }

          const isVasionComponent = (components[i]?.formioComponent?.originalComponent?.vasionUniqueFieldName?.length > 0) ||
             (components[i]?.formioComponent?.originalComponent?.type === "panel") ||
             (components[i]?.formioComponent?.originalComponent?.type === "columns") ||
             (components[i]?.formioComponent?.originalComponent?.type === "datagrid")
          if (isVasionComponent) {
            // The list of buttons is always children of the second node of the component
            const buttons = components[i]?.childNodes[1]?.childNodes
            if (buttons === undefined) continue
            for (let j = 0; j < buttons.length; j++) {
              const button = buttons[j]
              if (button.nodeType == 3) continue // Skip text nodes
              if (button.classList.contains('component-settings-button-copy')) {
                button.classList.add('hide')
              }
            }
          }
        }

        // Wizard button selection rebuilds the form, so when they are clicked, we also need to remove the copy buttons
        const wizardButtons = document.getElementsByClassName('wizard-page-label')
        for (let i = 0; i < wizardButtons.length; i++) {
          wizardButtons[i].addEventListener('click', this.removeCopyButton)
        }
      }, 200)
    },
    replaceFileOKClick() {
      this.showUploadPDFDialog = true
      this.toggleReplaceFileDialog()
    },    
    async replacePDFModalOKClick() {
      //This function is run if the user is warned that the PDF doesn't match the current PDF, but the user is OK with that

      this.saveEFormsPayload.GUID = this.replacePDFData.pdf.id
      //here we update the formioJSON
      let formioJSON = cloneDeep(this.activeFormIoJSON)
      formioJSON.settings = this.replacePDFData

      // Remove any components that are past the current number of pages
      if (this.hasNumPagesError) {
        const componentCount = formioJSON.components.length
        formioJSON.components = formioJSON.components.filter(c => c.overlay.page <= this.replacePDFData.numPages)
        if (formioJSON.components.length !== componentCount) {
          this.setEFormBuilderIsDirty()
        }
      }

      // Reset the component locations to the top-left if the page layout or size has changed
      this.latestPDFData = this.replacePDFData
      if (this.hasPageSizeError) {
          this.resetComponentLocations(formioJSON)
      }
      this.activeFormIoJSON = formioJSON
      this.hasNumPagesError = false
      this.hasPageSizeError = false

      this.pdfLoaded = true
      this.updateBuilderConfig(true)
      this.markAsDirty()

      this.toggleForceReplaceDialog()
    },
    resetComponentLocations(formioJSON) {
      // Take the components for each page and move them to the top left; stagger them by increasing the left and top by 20 pixels for each component
      var curPage = 0
      var pos = 0
      formioJSON.components.forEach(c => {
        if (c.overlay?.left) {
          if (c.overlay.page !== curPage) {
            pos = 0
            curPage = c.overlay.page
          }
          c.overlay.left = pos
          c.overlay.top = pos
          if (pos < 400) { 
            pos += 20
          }
        }
      })
      this.setEFormBuilderIsDirty()
    },
    async save() {
      if (this.pdfLoaded && !this.isPdfForm) {
        this.showChangePDFSaveDialog = true
        return
      }

      if (!await this.validate()) {
        return false
      }
      if (await this.saveEform()) {
        await this.saveTemplateMapping()
        this.markAsClean();
      }
    },
    async saveEform() {
      this.isLoading = true
      this.saveEFormsPayload.WorkflowID = this.selectedWorkflow?.value
      this.saveEFormsPayload.AccessUserIDs = this.saveEFormsPayload.PublicAccessible && !this.assignedUsers ? [] : this.assignedUsers?.map(e => e.value)?.filter(e => e != null)
      this.saveEFormsPayload.AccessGroupIDs = this.saveEFormsPayload.PublicAccessible ? [] : this.assignedGroups.map(e => e.value).filter(e => e != null)
      
      const fields = await this.$store.dispatch('forms/getEFormFieldsFromFormIOJson', {id: this.$route.params.formLayoutID, activeFormIoJSON: this.activeFormIoJSON})
      if (fields) {
        this.saveEFormsPayload.FormFields = fields.FormFields
      }
      await this.mapIndexToEform()

      this.saveEFormsPayload.HeaderImageBase64 = this.localHeaderImage
      this.activeFormIoJSON.components.forEach(c => {
        if (!c.vasionFieldName && c.vasionUniqueFieldName) {
          c.vasionFieldName = c.vasionUniqueFieldName
        }
      })
      if (this.latestPDFData?.pdf?.id)
      {
        this.activeFormIoJSON.settings.pdf.id = this.latestPDFData.pdf.id
        this.activeFormIoJSON.settings.pdf.src = this.latestPDFData.pdf.src
        this.saveEFormsPayload.FormIOJSON = JSON.stringify(this.activeFormIoJSON)
      } else if (this.eFormBuilderIsDirty) {
        this.saveEFormsPayload.FormIOJSON = JSON.stringify(this.activeFormIoJSON)
      }
      this.saveEFormsPayload.DBLookupIDs = this.databaseLookups.map(element => element.id)

      try {
        const res = await this.$store.dispatch('forms/saveEForm', this.saveEFormsPayload)
        if (res?.ErrorCode > 0) {
          switch (res?.ErrorCode) {
            case 1:
              this.isLoading = false
              this.showSnackbar(false, 'A form with the same Form Name already exists.')
              return false
          }
        }
        if (res?.EFormID && res.EFormID !== 0) {
          this.templateMappingSave.EFormID = res.EFormID
          this.defaultTemplateMappingSave.EFormID = res.EFormID
          this.saveEFormsPayload.GUID = res.GUID
          await this.$store.dispatch('forms/getEForm', res.EFormID)
          this.saveEFormsPayload.EFormID = res.EFormID
          this.$store.dispatch('forms/updateAreFormsDirty', false)
          this.isLoading = false
          this.showSnackbar(true)
        } else {
          this.isLoading = false
          this.showSnackbar(false)
          return false
        }
      } catch {
          this.isLoading = false
          this.showSnackbar(false)
          return false
      }
      return true
    },
    async savePDFFormSettings(newPdfSettings) {
      let formioJSON = cloneDeep(this.activeFormIoJSON)
      for (let [key, value] of Object.entries(newPdfSettings)) {
        if (key === 'fontSize' && value === 'auto') {
          delete(formioJSON.settings[key])
          continue
        }
        formioJSON.settings[key] = value
      }
      this.setEFormBuilderIsDirty()
      this.activeFormIoJSON = formioJSON
      this.showPDFFormSettings = false
    },
    async saveTemplateMapping() {
      this.isLoading = true
      const res = await this.$store.dispatch('forms/saveEFormDocTemplateDefaultMapping', this.defaultTemplateMappingSave)
      if (res) {
        this.$store.dispatch('forms/updateAreFormsDirty', false)
        this.showSnackbar(true)
        this.isLoading = false

        this.logFormSaved()
      } else {
        this.isLoading = false
        this.showSnackbar(false)
      }
    },
    async selectedFormTypeChanged(newFormType) {
      if (!newFormType.value) {
        this.selectedFormType = this.formTypes[0]
        this.selectedFormTypeChanged(this.selectedFormType)
        return
      }
      let formioJSON = cloneDeep(this.activeFormIoJSON)
      const oldFormType = this.activeFormIoJSON.display
      
      if (newFormType.value === 'pdf') {
        if (this.saveEFormsPayload.RequiresSignature === true) {
          this.showFormTypeChangeWarning = true
          return
        }
        this.showUploadPDFDialog = true
        this.pdfLoaded = false
      } else {
        delete formioJSON.settings
        this.latestPDFData = {}
      }
      
      formioJSON.display = newFormType.value

      //if going to or from PDF then clear components
      if (newFormType.value === 'pdf' || oldFormType === 'pdf') {
        formioJSON.components = []
      }

      this.activeFormIoJSON = formioJSON
      this.setEFormBuilderIsDirty()
      this.lastSelectedFormType = this.selectedFormType

      await this.fetchCustomFormBuilderFields()
      this.updateBuilderConfig(true)
      this.removeCopyButton()
    },
    setEFormBuilderIsDirty() {
      this.eFormBuilderIsDirty = true
      this.markAsDirty()
    },
    setFieldDefaultSize(component) {
      let width = component.overlay.width
      let height = component.overlay.height

      switch (component.type) {
        case 'currency':
        case 'datetime':
        case 'email':
        case 'htmlelement':
        case 'number':
        case 'phoneNumber':
        case 'select':
        case 'signature':
        case 'textarea':
        case 'textfield':
        case 'url':
          width = 146
          height = 24
          break;
      }

      component.overlay.width = width
      component.overlay.height = height
    },
    setFolderDialogCalledFrom(from) {
      this.folderDialogCalledFrom = from
    },
    showSnackbar(condition, failMessage) {
        this.showRawHtml = false
        this.snackbarTitle = condition ? `${$layoutsLabel} successfully saved!` : `Unable to save ${$layoutsLabel}.`
        if (!failMessage) {
          failMessage = 'Please try again or contact your administrator'
        }
        this.snackbarSubTitle = condition ? `You can view your ${$layoutsLabel} in "All ${$formsLabelPlural}"` : failMessage
        // eslint-disable-next-line
        this.snackbarImage = condition ? true : false
        this.showSnackbarBool = true;
    },
    toggleAttachmentSettingsDialog() {
      this.showAttachmentSettings = !this.showAttachmentSettings
    },
    toggleForceReplaceDialog() {
      this.showForceReplaceDialog = !this.showForceReplaceDialog
    },    
    toggleFullwidhtContainer() {
      this.fullWidthWrapperEnabled = !this.fullWidthWrapperEnabled
      this.$root.$emit('update-zoom', this.fullWidthWrapperEnabled)
    },
    async togglePreview(){
      this.showPreview = !this.showPreview
    },
    toggleReplaceFileDialog() {
      this.showReplaceFileDialog = !this.showReplaceFileDialog
    },
    toggleShowBrowseFoldersDialog() {
      this.showBrowseFoldersDialog = !this.showBrowseFoldersDialog
    },
    async updateBuilderConfig(forceRefresh) {
      // always reset number/currency/select incase we have changed type or dataGrids changed
      this.config.editForm.number = cloneDeep(builderConfig.editForm.number)
      this.config.editForm.currency = cloneDeep(builderConfig.editForm.currency)
      this.config.editForm.select = cloneDeep(builderConfig.editForm.select)

      this.config.builder.custom.components = this.builderConfigFromFormID
      this.config.builder.customBasic.ignore = this.isPdfForm
      this.config.builder.customAdvanced.ignore = this.isPdfForm
      this.config.builder.pdf.ignore = !this.isPdfForm

      if (this.isPdfForm) {
        this.config.zoom = -10
        const data = this.config.editForm.select.find(c => c.key === 'data')
        if (data) {
          const multiple = data.components?.find(c => c.key === 'multiple')
          if (multiple) {
            multiple.ignore = true
          }
        }
      } else {
        const updatedEditConfig = addTotalLogicDropList(builderConfig, this.activeFormIoJSON)
        if (updatedEditConfig.changed) {
          this.config.editForm.number = updatedEditConfig.number
          this.config.editForm.currency = updatedEditConfig.currency
        }
      }
      
      if (this.config.editForm?.file) await this.updateFileComponentSettings()
      
      //do the refresh/rebuild
      if (forceRefresh) {
        this.builderKey += 1
      }
      
      if(this.isMultiPageForm && this.$refs.formBuilder?.builder?.instance?.page >= 0) {
        const currentPageIndex = this.$refs.formBuilder.builder.instance.page
        this.$nextTick(() => {
          this.$refs.formBuilder.builder.instance.setPage(currentPageIndex)
        })
      }
    },
    updateFileComponentSettings() {
      const dropdownsFields = this.indexFieldsForForm.filter(field => field.type === 2)
      let values = []
      dropdownsFields.forEach(field => {
        field.dropdownValues.forEach(value => {
          values.push({label: `${field.name} - ${value.name}`, value: `${field.value}-${value.name}`})
        })
      })
      let indexTabToEdit = null
      this.config.editForm.file.forEach((tab, index) => {
        if (tab.key === 'file') {
          indexTabToEdit = index
        }
      })
      if (indexTabToEdit !== null) {
        this.config.editForm.file[indexTabToEdit].components.push({
          type: "select",
          input: true,
          key: "dropdownValueToMap",
          label: "Dropdown Value To Map",
          tooltip: "This is the dropdown value that will be mapped to the object.",
          weight: 2,
          dataSrc: "values",
          data: {
            values: values
          }
        })
      }
    },
    updateFileExtensionFields() {
      if (!this.saveEFormsPayload.FileExtensions.length)
        return
      let receivedCustomFileExtensions = []
      this.saveEFormsPayload.FileExtensions.split(',').map((fileExtension) => {
        if (this.allFileExtensions.includes(fileExtension)) {
          this.selectedFileExtensions.push({name: fileExtension, value: fileExtension})
          this.selectedFileExtensionsArrayBuffer.push(fileExtension)
        }else {
          receivedCustomFileExtensions.push(fileExtension)
          this.customFileExtensionsArrayBuffer.push(fileExtension)
        }
      })
      if (receivedCustomFileExtensions.length > 0) {
        this.selectedFileExtensions.push({name: 'CUSTOM', value: 'CUSTOM'})
        this.showCustomFileExtensionsField = true
        this.customFileExtensions = receivedCustomFileExtensions.join(',')
      }
    },
    updateSelectedTab(newSelectedTab) {
      this.selectedTabName = newSelectedTab
    },
    async validate() {
      let valid = false
      valid = await this.validateFormPage()

      if (!valid) {
        this.snackbarTitle = 'Unable to validate page'
        this.showRawHtml = true
        this.snackbarSubTitle = this.errorMessages.join('<br />')
        this.snackbarImage = false
        this.showSnackbarBool = true;
        this.errorMessages = []
      }

      return valid
    },
    async validateFormPage() {
      let valid = true

      if (!this.saveEFormsPayload || !this.saveEFormsPayload.Name || this.saveEFormsPayload.Name === '') {
        valid = false
        this.errorMessages.push(`- Please provide a name for the ${$layoutsLabel.toLowerCase()}.`)
      }

      if (this.saveEFormsPayload.Name.trim() === '') {
        valid = false
        this.errorMessages.push('- The Form Name cannot only include spaces.')
      }

      if (this.saveEFormsPayload.Name.includes('\\') ||
        this.saveEFormsPayload.Name.includes('/') ||
        this.saveEFormsPayload.Name.includes('*') ||
        this.saveEFormsPayload.Name.includes('|') ||
        this.saveEFormsPayload.Name.includes(':') ||
        this.saveEFormsPayload.Name.includes('"') ||
        this.saveEFormsPayload.Name.includes('<') ||
        this.saveEFormsPayload.Name.includes('>') ||
        this.saveEFormsPayload.Name.includes('?')
      ) {
        valid = false
        this.errorMessages.push('- The Form Name cannot include any of these special characters: \\, /, *, |, :, ", <, >, or ?')
      }

      const filteredComponents = this.activeFormIoJSON?.components.filter(c => c.key !== 'submit')
      if (!filteredComponents || !filteredComponents.length > 0) {
        valid = false
        this.errorMessages.push(`- Please provide at least one field on the ${$layoutsLabel.toLowerCase()}.`)
      }

      await this.prepairDefaultTemplateMappingForSave()
      if (this.saveEFormsPayload && this.defaultTemplateMappingSave.DocumentTemplateIDs.length == 0 && !this.saveEFormsPayload.GenerateFormPdf) {
        if (!this.createTempDocsByFormID) {
          this.errorMessages.push('- Please select Generate Form PDF; there are no Document Templates available.')
        }
        this.errorMessages.push('- A Document Template or a Generate Form PDF method must be selected.')
        valid = false
      }

      if (!this.saveEFormsPayload.GenerateFormPdf) {
        this.selectedWorkflow = { name: "[None]", value: null }
        this.saveEFormsPayload.FolderID = null
        this.saveEFormsPayload.FolderName = null
        this.selectedFormPDFDestination = { id: null, value: null }
      } else if (this.saveEFormsPayload.GenerateFormPdf && this.selectedFormPDFDestination.value === 'Storage') {
        this.selectedWorkflow = { name: "[None]", value: null }
        if (!this.saveEFormsPayload.FolderName || this.saveEFormsPayload.FolderName === '') {
          valid = false
          this.errorMessages.push('- Please select a Destination Folder for Form PDF Destination.')
        }
      } else if (this.saveEFormsPayload.GenerateFormPdf && this.selectedFormPDFDestination.value === 'Workflow') {
        this.saveEFormsPayload.FolderName = null
        if (!this.selectedWorkflow.value || this.selectedWorkflow?.value === 0) {
          valid = false
          this.errorMessages.push('- Please select a Workflow for Form PDF Destination.')
        }
      } else {
        valid = false
        this.errorMessages.push('- Please select a Form PDF Destination type.')
      }

      return valid
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/variables.scss';
@import '@/assets/css/light-theme-system-palette.scss';

#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: left;
  color: #2c3e50;
  overflow: hidden;
}
#browse-folders-for-eform {
  position: relative;
  top: 16px;
  margin-right: 40px;
}

.vasion-flex{
  .destination-side-padding{
    padding-left: 1em;
  }
}

#new-formio-title {
  width: 500px;
  position: absolute;
  margin-top: -35px;
  margin-left: -15px;
}

.droplist{
  margin-bottom: 24px;
}

.description-text{
  font-size: 14px;
  color: #838587;
  margin-bottom: 24px;
}

.map-forms{
  overflow: auto;
  max-height: calc(100vh - 188px);
}

.collapse-button{
  margin-right: 16px;
  align-items: center;
  background-color: #f1f2f3;
  border-radius: 8px;
}

.collapsible-section{
  display: flex;
  align-items: center;
  margin-top: 36px;
}

.subheader{
  font-size: 20px;
}

.custom-logo-extension {
  width: 1004px;
  min-height: 100px;
  max-height: 172px;
  background-color: $white;
  position: relative;
  margin: auto;
  display: flex;
}
.header-title {
  width: calc(100vw - 380px);
  margin-left: 242px;
  padding-left: 15px;
  padding-right: 15px;
}

::v-deep .formarea {
  width: calc(100vw - 380px) !important;
  margin-left: 242px !important;
  padding-top: 10px;
}

#upload-new-file-dialog {
    padding: 10px;
    width: 500px;
    height: 420px
  }

.upload-container {
  border-radius: 1px solid red;
  overflow: auto;
  height: 100%;
}
.folder-dialog-browse {
  width: 400px;
  height: 300px;
  overflow: auto;
  z-index: 30;
}
.formio-extension {
  width: 1004px;
  height: 122px;
  background-color: $white;
  position: relative;
  top: 1px;
  z-index: 1;
  margin: 0 auto;
  #upload-pdf-div {
    display: flex;
    align-items: end;
    justify-content: end;
  }
  #upload-pdf-div button {
    margin-right: 0px;
  }
}
.formio-input-extension {
  background-color: white;
  z-index: 200;
  display: flex;
  flex-direction: column;
}
.formio-header {
  height: 72px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  position: relative;
  box-shadow: 0 1px 0 0 #e4e5e6;
  padding-bottom: 15px;
  z-index: 2;
}
.formio-preview-header {
  height: 72px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  background-color: #fff;
  position: relative;
  box-shadow: 0 1px 0 0 #e4e5e6;
  padding-bottom: 15px;
  z-index: 2;
}
.form-name {
  border: none;
  font-size: 24px;
  font-weight: bold;
  width: calc(100% - 220px);
  padding: 30px 24px 8px 24px;
  outline: none;
  border-radius: 8px 8px 0 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.form-name:focus,
.form-description:focus {
  background-color: $grey-75;
}
.form-description {
  border: none;
  width: calc(100% - 220px);
  font-size: 18px;
  padding: 0px 0px 30px 24px;
  outline: none;
  border-bottom: 1px solid #d6d8da;
}
.header-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.header-image {
  box-sizing: border-box;
  max-width: 100%;
  max-height: 100%;
  width: auto;
}
.header-icon-buttons {
  position: absolute;
  top: 15px;
  right: 5px;
  z-index: 1;
}
.highlightBackground {
  min-width: 34px;
  min-height: 34px;
  background-color: $grey-75;
  border-radius: 8px;
}
.highlightBackground:after {
  background-color: $grey-100;
}
.left-padding {
  padding-left: 15px;
}
.margin-bottom {
  margin-bottom: 22px;
  color: $grey-400;
}
.form-builder-margin-10 {
  margin-top: 10px;
  margin-bottom: 10px;
}
.fixedWidth {
  width: 250px;
}
.browse-folder-padding {
  padding-bottom: 10px;
  padding-top: 10px;
}
.scroll-me {
  height: calc(100vh - 192px);
  overflow-y: scroll;
  overflow-x: hidden;
}
.temp-list-selection-styles {
  min-height: 300px;
  margin-top: 20px;
}
.tabs {
  position: absolute;
  left: -5px;
  top: 30px;
}
.vasion-settings-card {
  width: 100%;
  min-height: calc(100vh - 192px);
  z-index: 3000;
  background-color: $white;
  padding: 22px;
}
#vasion-form-builder .formio-form {
  position: relative;
  min-height: 80px;
  z-index: 5;
}
.with-fix {
  background-color: white;
  padding: 0px 8px 0px 8px;
  position: absolute;
  left: 0px;
  top: 72px;
  width: 260px;
}
.row-checkbox {
  margin-top: 30px;
  margin-left: 16px;
}
@media screen and (max-width: 1535px) {
  .formio-extension {
    width: 890px;
  }
  .custom-logo-extension {
    width: 890px;
  }
}
@media screen and (max-width: 1400px) {
  .formio-extension {
    width: 700px;
  }
  .custom-logo-extension {
    width: 700px;
  }
}

#eform-hide-preview {
  margin-right: 1rem;
}
.attachment-settings-container {
  min-width: 40vw;
  margin-top: 1rem;
  max-width: 600px;
}
.attachment-settings-container .list {
  position: unset !important;
}
.mt-4 {
  margin-top: 1.5rem;
}
.input-note {
  margin-left: 0.5rem;
  color: #95989a;
}
// xs
.full-width-btn {
  display: none !important;
}
.form-container {
  width: 100%;
  height: calc(100vh - 152px);
  padding: 1rem;
}
.form-wrapper {
  border-radius: 0.5rem;
  box-shadow: 0 .125rem .25rem rgba(33,37,41,.075);
  background-color: $white;
  overflow-y: auto;
  left: 72px;
  max-height: calc(100vh - 172px);
  width: 100%;
}
.full-width {
  width: 100% !important;
}
// sm: 576px
@media (min-width: $breakpoint-sm) {
  .form-wrapper {
    max-height: calc(100vh - 166px);
  }
}
// md: 768px
@media (min-width: $breakpoint-md) {
  .full-width-btn {
    display: block !important;
    margin-right: 16px;
  }
  .form-container {
    height: calc(100vh - 178px);
    display: flex;
    justify-content: center;
  }
  .form-wrapper {
    max-height: calc(100vh - 150px);
    width: 736px;
  }
}
// prevent side bar
@media (min-width: 950px) and (max-width: 991.98px) {
  .form-container {
    height: calc(100vh - 178px);
    display: flex;
    justify-content: center;
  }
  .form-wrapper {
    width: 918px - 330px;
  }
}
// lg: 992px
@media (min-width: $breakpoint-lg) {
  .form-container {
    height: calc(100vh - 178px);
    display: flex;
    justify-content: center;
  }
  .form-wrapper {
    width: 960px - 330px;
  }
}
// xl: 1200px
@media (min-width: $breakpoint-xl) {
  .form-container {
    height: calc(100vh - 178px);
  }
  .form-wrapper {
    width: 1168px - 330px;
  }
}
// xxl: 1400px
@media (min-width: $breakpoint-xxl) {
  .form-wrapper {
    width: 1368px - 330px;
  }
}
.warning-message {
  max-width: 420px;
}
.btn-pdf-settings {
  margin: 6px 8px;
}

::v-deep .md-radio.md-theme-default.md-checked .md-ripple {
  color: $secondary !important;
}
::v-deep .md-radio.md-theme-default.md-checked .md-radio-container {
  border-color: $secondary !important;
}
::v-deep .md-radio.md-theme-default.md-checked .md-radio-container:after {
  background-color: $secondary !important;
}
</style>
