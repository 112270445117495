<template>
  <div>
    <VasionGeneralModal
      v-show="showModal"
      id="pdf-form-settings-dialog"
      modalType="slot"
      rejectButtonText="Cancel"
      confirmButtonText="Done"
      :modalTitle="'Manage PDF Form Settings'"
      :sync="true"
      :showTopRightCloseButton="true"
      @noButtonClick="hideModal"
      @confirmButtonClick="save"
    >
      <div class="pdf-form-settings-form">
        <div class="form-row">
          <VasionDropList
            v-slot="slotItem"
            v-model="formFieldsData.fontSize"
            title="Font size (px)"
            placeholder="Select Font size..."
            :dataArray="fieldLists.fontSize"
            width="100%"
            :showSearchField="false"
            type="plain-list"
            displayName="displayName"
            valueName="value"
            :required="true"
            :overlayList="true"
          >
            {{ slotItem.item.displayName }}
          </VasionDropList>
          <VasionDropList
            v-slot="slotItem"
            v-model="formFieldsData.layout"
            title="Page Layout"
            placeholder="Select Page Layout..."
            :dataArray="fieldLists.layout"
            width="100%"
            :showSearchField="false"
            type="plain-list"
            displayName="displayName"
            valueName="value"
            :required="true"
            :overlayList="true"
          >
            {{ slotItem.item.displayName }}
          </VasionDropList>
          <VasionDropList
            v-slot="slotItem"
            v-model="formFieldsData.pageSize"
            title="Page Size"
            placeholder="Select Page Size..."
            :dataArray="fieldLists.pageSize"
            width="100%"
            :showSearchField="false"
            type="plain-list"
            displayName="displayName"
            valueName="value"
            :required="true"
            :overlayList="true"
          >
            {{ slotItem.item.displayName }}
          </VasionDropList>
        </div>
      </div>
    </VasionGeneralModal>
    <VasionSnackbar
      id="snackbar-textract-form"
      :showSnackbarBool.sync="showSnackbar"
      :snackbarImage="snackbarImage"
      :snackbarSubTitle="snackbarSubTitle"
      :snackbarTitle="snackbarTitle"
    />
  </div>
</template>

<script>
import list from './PDFFormFieldsLists.json';

export default {
  name: 'PDFFormSettingsForm',
  props: {
    data: {
      type: Object,
      required: false,
      default: null,
    },
    showPDFFormSettings: {
      type: Boolean,
      required: false,
      default: false
    },
  },
  data() {
    return {
      formData: this.data,
      fieldLists: {
        fontSize: list.fontSizes,
        layout: list.layouts,
        pageSize: list.pageSizes,
      },
      formFieldsData: {
        fontSize: list.fontSizes[0],
        layout: {},
        pageSize: {},
      },
      showSnackbar: false,
      snackbarImage: false,
      snackbarSubTitle: '',
      snackbarTitle: '',
    }
  },
  computed: {
    showModal() {
      return this.showPDFFormSettings
    }
  },
  watch: {
    async data(newValue) {
      if (Object.keys(newValue).length) await this.setFormValues(newValue)
    },
  },
  async created() {
    if (Object.keys(this.formData).length) await this.setFormValues(this.formData)
  },
  methods: {
    save() {
      if (!this.checkIfFormIsValid()) return

      let payload = {
        fontSize: this.formFieldsData.fontSize.value,
        layout: this.formFieldsData.layout.value,
        pageSize: this.formFieldsData.pageSize.value,
      }
      this.$emit('save', payload)
    },
    checkIfFormIsValid() {
      if (!this.formFieldsData.fontSize) {
        this.snackbarTitle = "Required field missing"
        this.snackbarSubTitle = "Please select a Font size"
        this.snackbarImage = false
        this.showSnackbar = true
        return false
      }

      if (!this.formFieldsData.layout) {
        this.snackbarTitle = "Required field missing"
        this.snackbarSubTitle = "Please select a Page Layout"
        this.snackbarImage = false
        this.showSnackbar = true
        return false
      }

      if (!this.formFieldsData.pageSize) {
        this.snackbarTitle = "Required field missing"
        this.snackbarSubTitle = "Please select a Page Size"
        this.snackbarImage = false
        this.showSnackbar = true
        return false
      }

      return true
    },
    hideModal() {
      this.$emit('close')
    },
    async setFormValues(data) {
      for (let [key, value] of Object.entries(data)) {
        if (!(key in this.formFieldsData)) continue
        // VasionInput, inputType:top-white
        if (!(key in this.fieldLists)) {
          this.formFieldsData[key] = value
          continue
        }
        // VasionDropList
        if (key in this.fieldLists) {
          this.formFieldsData[key] = this.fieldLists[key].find(item => item.value === value) || {}
          continue
        }
      }
    }
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/variables.scss';

.pdf-form-settings-form {
  min-width: 40vw;
  max-width: 600px;
}

div.form-row {
  padding: 6px;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
  grid-gap: 8px;
  margin-top: 8px;
  margin-bottom: 8px;
}
</style>
